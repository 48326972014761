<template>
  <nuxt-link
    :to="`/blogs/${blog.url}`"
    :external="true"
    class="pxp-prop-card-1 rounded-lg tw-w-full tw-h-[270px]"
  >
    <div
      class="pxp-prop-card-1-fig pxp-cover"
      :style="`background-image: url(${blog.image})`"
    ></div>
    <div class="pxp-prop-card-1-gradient pxp-animate"></div>
    <div class="pxp-prop-card-1-details">
      <div class="pxp-prop-card-1-details-title">
        <lazy-library-title
          tag="h5"
          class="tw-font-bold tw-line-clamp-3"
          :text="blog.title"
        >
        </lazy-library-title>
      </div>
      <div class="pxp-prop-card-1-details-price"></div>
      <div
        v-if="false"
        class="pxp-prop-card-1-details-features text-uppercase"
      ></div>
    </div>
    <div class="pxp-prop-card-1-details-cta">
      <div class="pxp-animate tw-mt-5 tw-line-clamp-3">
        {{ blog.description }}
      </div>
    </div>
  </nuxt-link>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import type { BlogItemComponent } from '~/units/blogs/types';

export default defineNuxtComponent({
  name: 'Theme3BlogCard',

  props: {
    blog: {
      required: true,
      type: Object as PropType<BlogItemComponent>,
    },

    index: {
      required: true,
      type: Number,
    },
  },
});
</script>

<style scoped>
.pxp-prop-card-1:hover .pxp-prop-card-1-details {
  bottom: 35px;
}

.pxp-prop-card-1 .pxp-prop-card-1-fig:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 50% black overlay */
}

.pxp-prop-card-1:hover .pxp-prop-card-1-fig:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 205, 212, 0.3);
}
</style>
