<template>
  <div v-if="property" class="tw-w-[500px]">
    <div class="pxp-prop-card-1 rounded-lg mb-4">
      <div
        class="pxp-prop-card-1-fig pxp-cover"
        :style="`background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)), url(${property.image})`"
      ></div>
      <div
        v-if="property && property.custom_property_status_title"
        class="nc-property-image-badge"
      >
        {{ property.custom_property_status_title }}
      </div>
      <div class="pxp-prop-card-1-gradient pxp-animate"></div>
      <div class="pxp-prop-card-1-details">
        <div class="pxp-prop-card-1-details-title">
          {{ property.advert_heading }}
        </div>
        <div class="pxp-prop-card-1-details-price tw-flex tw-flex-col">
          <small class="tw-capitalize">{{ property.price.type?.name }}</small>
          {{ property.price.price }}
        </div>
        <div class="pxp-prop-card-1-details-features text-uppercase">
          {{ property.bedroom }} BD <span>|</span> {{ property.bathroom }} BA
          <span>|</span> {{ property.reception }} RE
        </div>
      </div>
      <div class="pxp-prop-card-1-details-cta text-uppercase">View Details</div>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import type { PropertyItemComponent } from '~/units/properties/types';

export default defineNuxtComponent({
  name: 'PropertyListItem',

  props: {
    property: {
      required: true,
      type: Object as PropType<PropertyItemComponent>,
    },
  },

  computed: {},
});
</script>

<style scoped></style>
