<template>
  <div>
    <div
      v-if="variants.v1 === variant"
      class="pxp-cta-1 pxp-cover card-section-v1 tw-flex tw-h-[80vh] tw-justify-center"
      :class="`tw-items-${vars.cardPositionText} tw-items-`"
      :style="`
      background-image: url(${vars.sectionBackgroundImage});
      background-position: 50% 60%;
    `"
    >
      <div class="container">
        <div
          v-if="variants.v1 === variant"
          class="row justify-content-center tw-items-end"
        >
          <div class="col-sm-12 col-md-9 col-lg-6">
            <div
              class="tw-px-12 tw-py-14"
              :style="`background-color: var(--${vars.cardBackgroundColor})`"
            >
              <h2 class="font-plus-jakarta-semibold tw-mb-7 tw-text-2xl">
                {{ vars.cardTitleText }}
              </h2>
              <p class="font-arial tw-mb-14">{{ vars.cardDescriptionText }}</p>
              <nuxt-link
                :external="true"
                :to="vars.cardButtonUrl"
                class="pxp-search-properties-btn"
                :style="`background-color: var(--${vars.cardButtonBackgroundColor}); color: var(--${vars.cardButtonTextColor});`"
                >{{ vars.cardButtonText }}</nuxt-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="variants.v2 === variant"
      class="pxp-cta-1 pxp-cover mt-100 tw-flex tw-h-[80vh]"
      :class="`tw-items-${vars.cardPositionYText}`"
      :style="`
      background-image: url(${vars.sectionBackgroundImage});
      background-position: 50% 60%;
    `"
    >
      <div class="container">
        <div class="row" :class="`tw-justify-${vars.cardPositionXText}`">
          <div
            :class="
              vars.buttonListingDirectionText === 'horizontal'
                ? 'col-sm-12 col-md-8 col-lg-6 col-xl-5'
                : 'col-md-6 col-lg-4'
            "
          >
            <div class="pxp-cta-1-caption pxp-animate-in tw-p-5 md:tw-p-10">
              <library-title
                tag="h3"
                :text="vars.titleText"
                class="tw-relative"
              ></library-title>

              <library-text
                type="standard"
                tag="p"
                :text="vars.descriptionText"
                class="tw-mt-4"
              ></library-text>

              <div
                class="tw-flex"
                :class="
                  vars.buttonListingDirectionText === 'horizontal'
                    ? 'tw-flex-col tw-gap-x-2 md:tw-flex-row md:tw-gap-x-4'
                    : 'tw-flex-col'
                "
              >
                <nuxt-link
                  v-if="vars.buttonStyleText !== 'primary'"
                  :to="vars.buttonUrl"
                  :external="true"
                  class="pxp-primary-cta text-uppercase mt-3 mt-md-5 pxp-animate after:tw-border-inherit"
                  :style="`color: var(--${vars.buttonColor}); border-top-color: var(--${vars.buttonColor})`"
                  >{{ vars.buttonText }}</nuxt-link
                >
                <div
                  v-else-if="vars.buttonStyleText === 'primary'"
                  class="tw-mt-4"
                >
                  <nc-button
                    :link="vars.buttonUrl"
                    :is-link="true"
                    :external="true"
                    :label="vars.buttonText"
                    >{{ vars.buttonText }}</nc-button
                  >
                </div>
                <nuxt-link
                  v-if="vars.secondaryButtonStyleText !== 'primary'"
                  :to="vars.secondaryButtonUrl"
                  :external="true"
                  class="pxp-primary-cta text-uppercase pxp-animate tw-mt-6 after:tw-border-inherit"
                  :style="`color: var(--${vars.secondaryButtonColor}); border-top-color: var(--${vars.secondaryButtonColor})`"
                  >{{ vars.secondaryButtonText }}</nuxt-link
                >
                <div
                  v-else-if="vars.secondaryButtonStyleText === 'primary'"
                  class="tw-mt-4"
                >
                  <nc-button
                    :link="vars.secondaryButtonUrl"
                    :is-link="true"
                    :external="true"
                    :label="vars.secondaryButtonText"
                    >{{ vars.secondaryButtonText }}</nc-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="variants.v3 === variant">
      <div
        class="pxp-services pxp-cover mt-100 pt-100 mb-200 tw-relative tw-flex tw-h-[418px] tw-items-center tw-justify-center max-lg:tw-mb-[100px] max-md:tw-mb-[300px]"
        style="background-position: 75% 60%"
        :style="`background-image: url(${vars.backgroundImage});`"
      >
        <div
          class="rounded-lg mt-4 mt-md-5 tw-absolute tw-flex tw-items-center tw-justify-center tw-shadow-[0px_15px_30px_rgba(0,0,0,0.1)] max-md:tw-bottom-[-220px] lg:tw-bottom-[-140px]"
        >
          <div class="tw-w-full tw-max-w-[818px]">
            <div
              class="tw-px-12 tw-py-14 tw-text-center"
              :style="`background-color: var(--${vars.cardBackgroundColor})`"
            >
              <h2 class="font-plus-jakarta-semibold tw-mb-7 tw-text-2xl">
                {{ vars.titleText }}
              </h2>
              <p class="font-arial tw-mb-14">
                {{ vars.descriptionText }}
              </p>
              <nuxt-link
                :to="vars.buttonUrl"
                :external="true"
                class="pxp-search-properties-btn"
                :style="`background-color: var(--${vars.buttonBackgroundColor}); color: var(--${vars.buttonTextColor});`"
                >{{ vars.buttonText }}</nuxt-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="variants.v4 === variant"
      style="background-color: unset !important"
    >
      <div class="">
        <library-title
          tag="h3"
          :text="vars.titleText"
          class="tw-relative"
        ></library-title>

        <library-text
          type="standard"
          tag="p"
          :text="vars.descriptionText"
          class="tw-mt-4"
        ></library-text>

        <div class="tw-flex tw-flex-col">
          <nuxt-link
            :to="vars.buttonUrl"
            :external="true"
            class="pxp-primary-cta text-uppercase mt-3 mt-md-5 pxp-animate tw-cursor-pointer after:tw-border-inherit"
            :style="`color: var(--${vars.buttonColor}); border-top-color: var(--${vars.buttonColor})`"
            >{{ vars.buttonText }}</nuxt-link
          >
          <nuxt-link
            :to="vars.secondaryButtonUrl"
            :external="true"
            class="pxp-primary-cta text-uppercase pxp-animate tw-mt-6 tw-cursor-pointer after:tw-border-inherit"
            :style="`color: var(--${vars.secondaryButtonColor}); border-top-color: var(--${vars.secondaryButtonColor})`"
            >{{ vars.secondaryButtonText }}</nuxt-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import NcButton from '~/components/common/inputs/NcButton.vue';

export default defineNuxtComponent({
  name: 'CardSection',
  components: { NcButton },

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'CardSectionV2',
        v3: 'CardSectionV3',
        v4: 'SidebarV4',
      },
    };
  },

  computed: {
    variant() {
      return useVariant(this.component);
    },
  },
});
</script>

<style scoped></style>
