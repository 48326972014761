<template>
  <div v-if="variants.v1 === variant" class="tw-pb-24">
    <div class="container">
      <lazy-library-title
        :text="vars.titleText"
        class="text-center"
        tag="h2"
      ></lazy-library-title>
    </div>

    <library-container>
      <div class="pxp-areas-carousel mt-4 mt-md-5">
        <div
          class="pxp-areas-carousel-stage owl-carousel properties-carousel-stage"
        >
          <nuxt-link
            v-for="(property, index) in properties"
            :key="`property-${index}`"
            class="pxp-areas-carousel-item"
            :external="true"
            :to="property.detail_page_url"
          >
            <theme3-properties-property-list-item
              :property="property"
              :is-match-information-hidden="true"
            />
          </nuxt-link>
        </div>
      </div>
    </library-container>

    <div class="container">
      <div class="text-center mt-4 mt-md-5">
        <nuxt-link
          :external="true"
          :to="ctaUrl"
          class="pxp-primary-cta text-uppercase pxp-animate"
        >
          {{ ctaText }}
        </nuxt-link>
      </div>
    </div>
  </div>
  <div v-if="variants.v2 === variant">
    <div class="container">
      <lazy-library-title
        :text="vars.titleText"
        class="text-left"
        tag="h3"
      ></lazy-library-title>
    </div>

    <library-container>
      <div class="pxp-areas-carousel mt-4 mt-md-5" style="padding-left: 0">
        <div
          class="pxp-areas-carousel-stage owl-carousel properties-wide-card-carousel-stage"
        >
          <nuxt-link
            v-for="(property, index) in properties"
            :key="`property-${index}`"
            class="pxp-areas-carousel-item"
            :external="true"
            :to="property.detail_page_url"
          >
            <theme3-properties-property-list-item-wide-card
              :property="property"
            />
          </nuxt-link>
        </div>
      </div>
    </library-container>

    <div class="container">
      <div class="text-left mt-4 mt-md-5">
        <nuxt-link
          :external="true"
          :to="ctaUrl"
          class="pxp-primary-cta text-uppercase pxp-animate"
        >
          {{ ctaText }}
        </nuxt-link>
      </div>
    </div>
  </div>
  <div v-if="variants.v3 === variant" style="text-align: left">
    <lazy-library-title
      :text="vars.titleText"
      tag="h3"
      class="tw-mb-6"
    ></lazy-library-title>
    <nuxt-link
      v-for="(property, index) in properties"
      :key="`property-${index}`"
      class="pxp-areas-carousel-item"
      style="width: 100% !important;"
      :external="true"
      :to="property.detail_page_url"
    >
      <theme3-properties-property-list-item
        :property="property"
        :is-match-information-hidden="true"
        :is-simple-version="true"
      />
      <div style="text-align: left;" class="tw-mt-6">
        <div
          class="pxp-primary-cta text-uppercase pxp-animate tw-cursor-pointer after:tw-border-inherit"
          :style="`color: var(--purple); border-top-color: var(--purple)`"
        >{{ ctaText }}</div
        >
      </div>
    </nuxt-link>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { PropertiesMixin } from '~/units/properties/mixins/properties.mixin';
import { jQuery } from 'public/theme3/js/custom';
import { ROUTES } from '~/constants/ui.constants';

export default defineNuxtComponent({
  name: 'Theme3PropertiesBox',

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2WideCardSlider',
        v3: 'v3Sidebar'
      },
    };
  },

  computed: {
    ctaText() {
      return this.vars.ctaText || 'All Properties';
    },

    ctaUrl() {
      return this.vars.ctaUrl || ROUTES.PROPERTIES;
    },
  },

  mixins: [ComponentMixin, PropertiesMixin],

  async setup(props) {
    await fetchPropertiesManager(props.component, null);
  },

  mounted() {
    if (this.variant === this.variants.v1) {
      jQuery.propertiesSlider();
    } else if (this.variant === this.variants.v2) {
      jQuery.propertiesWideCardSlider();
    }
  },
});
</script>

<style scoped>
::v-deep(.pxp-areas-carousel-right-arrow) {
  top: 22% !important;
}

::v-deep(.pxp-areas-carousel-left-arrow) {
  top: 22% !important;
}

::v-deep(.properties-wide-card-carousel-stage .pxp-areas-carousel-right-arrow) {
  top: 50% !important;
}

::v-deep(.properties-wide-card-carousel-stage .pxp-areas-carousel-left-arrow) {
  top: 50% !important;
}
</style>
