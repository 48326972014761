<template>
  <div class="nc-text">
    <lazy-library-container v-if="variant === variants.v1">
      <div>
        <lazy-library-title
          v-if="titleHtml"
          :html="titleHtml"
          tag="h2"
          class="tw-mb-10"
        ></lazy-library-title>
        <div v-if="isBlogsLoading">
          <loader :loader-color="colors.brown" />
        </div>
        <div v-else-if="!isBlogsLoading" class="tw-grid tw-grid-cols-12 tw-gap-y-8 lg:tw-gap-x-4">
          <div
            v-for="(blog, index) in blogs"
            :key="`blog-${index}`"
            class="tw-col-span-12 lg:tw-col-span-6 xl:tw-col-span-4"
          >
            <lazy-theme3-blogs-blog-card :blog="blog" :index="index" />
          </div>
        </div>
        <div>
          <nuxt-link
            :to="ROUTES.BLOGS"
            :external="true"
            class="pxp-primary-cta text-uppercase mt-md-4 animate tw-flex tw-justify-center tw-items-center tw-text-[var(--purple)]"
            >View All Blogs</nuxt-link
          >
        </div>
      </div>
    </lazy-library-container>
    <div
      v-else-if="variant === variants.v2"
      class="tw-flex tw-flex-col tw-gap-y-4"
    >
      <lazy-library-title
        v-if="titleHtml"
        :html="titleHtml"
        tag="h3"
      ></lazy-library-title>
      <div>
        <nuxt-link
          :to="ROUTES.BLOGS"
          :external="true"
          class="pxp-primary-cta text-uppercase animate tw-flex tw-justify-start tw-items-center tw-text-[var(--purple)]"
          >View All Blogs</nuxt-link
        >
      </div>
      <div v-if="isBlogsLoading">
        <loader :loader-color="colors.brown" />
      </div>
      <div v-else-if="!isBlogsLoading" class="pxp-areas-carousel tw-px-0">
        <div
          class="pxp-areas-carousel-stage knowledge-hub-carousel-stage owl-carousel"
        >
          <div
            v-for="(blog, index) in blogs"
            :key="`blog-${index}`"
            class="pxp-areas-carousel-item"
          >
            <lazy-theme3-blogs-blog-card :blog="blog" :index="index" />
          </div>
        </div>
      </div>
    </div>
    <lazy-library-container v-if="variant === variants.v3">
      <div>
        <lazy-library-title
          v-if="titleHtml"
          :html="titleHtml"
          tag="h3"
          class="tw-mb-10"
        ></lazy-library-title>
        <div v-if="isBlogsLoading">
          <loader :loader-color="colors.brown" />
        </div>
        <div
          v-else-if="!isBlogsLoading"
          class="tw-grid tw-grid-cols-12 tw-gap-y-6 lg:tw-gap-x-6 lg:tw-gap-y-0"
        >
          <div
            v-for="(blog, index) in blogs"
            :key="`blog-${index}`"
            class="tw-col-span-12 lg:tw-col-span-4"
          >
            <theme3-blogs-blog-item-wide-card :blog="blog" :index="index" />
          </div>
        </div>
        <div>
          <nuxt-link
            :to="ROUTES.BLOGS"
            :external="true"
            class="pxp-primary-cta text-uppercase tw-mt-8 animate tw-flex tw-justify-start tw-items-center"
            style="color: #666666"
            >{{ ctaText }}</nuxt-link
          >
        </div>
      </div>
    </lazy-library-container>
  </div>
</template>

<script lang="ts">
import type { Component } from '~/types';
import Loader from '~/components/common/Loader.vue';
import NotFound from '~/components/theme2/base/NotFound.vue';
import indexGlobal from '~/mixins/index.global';
import { BlogsMixin } from '~/units/blogs/mixins/blogs.mixin';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { ComponentMixin } from '~/mixins/component.mixin';
import { useBlogsStore } from '~/units/blogs/store';
import SearchBox from '~/components/common/SearchBox.vue';
import { ROUTES } from '~/constants/ui.constants';
import { jQuery } from 'public/theme3/js/custom';

export default defineNuxtComponent({
  name: 'Theme3BlogsBox',
  components: { SearchBox, NcButton, NotFound, Loader },

  mixins: [BlogsMixin, indexGlobal, ComponentMixin],

  async setup(props: { component: Component }) {
    const blogsStore = useBlogsStore();
    await blogsStore.fetchBlogsInPage(props.component);
  },

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'SidebarV2',
        v3: 'WideCardV3',
      },
      searchBoxPlaceholder: 'Search',
    };
  },

  mounted() {
    if (this.variant === this.variants.v2) {
      jQuery.blogSidebarSlider();
    }
  },

  computed: {
    ROUTES() {
      return ROUTES;
    },

    ctaText() {
      return this.vars.ctaText || 'View All Blogs';
    },

    isLatestCarouselEnabled() {
      return this.vars.isLatestCarouselEnabledNumber;
    },

    isLatestCarouselVisible() {
      const route = useRoute();
      return (
        this.isLatestCarouselEnabled &&
        this.blogs.length !== 0 &&
        !route.query.q &&
        !route.query.page
      );
    },

    variant() {
      return useVariant(this.component);
    },

    backgroundColor() {
      return getVariable(this.component, 'background-color');
    },

    titleHtml() {
      return this.vars.titleHtml;
    },
  },

  methods: {},

  watch: {},
});
</script>

<style scoped>
::v-deep(.pxp-areas-carousel-right-arrow) {
  top: 40% !important;
}

::v-deep(.pxp-areas-carousel-left-arrow) {
  top: 40% !important;
}
</style>
